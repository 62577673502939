:root {
  --box-show: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  --primary-color: ijdfdk;
  --text-color: owerio;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  font-size: 13px;
}

.placecolor::placeholder {
  color: #0d6efd;
  opacity: 1;
  font-weight: bold;
}


@keyframes blinker {
  from {
    opacity: 1.0;
  }

  to {
    background-color: red;
    color: white;
  }
}

.blink {
  animation: blinker 0.6s ease-in-out 2s infinite alternate;
  -webkit-animation: blinker 0.6s ease-in-out 2s infinite alternate;
}

@keyframes blinker_green {
  from {
    opacity: 1.0;
  }

  to {
    background-color: green;
    color: white;
  }
}

.blink_green{
  animation: blinker_green 0.6s ease-in-out 2s infinite alternate;
  -webkit-animation: blinker_green 0.6s ease-in-out 2s infinite alternate;
}




.pop-monitoring-ac-box {
  border-radius: 3px;
  box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.muted-btn {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  border: none;
  outline: none;
  padding: 1px 8px;
  margin: 0;
  color: white;
  z-index: 9999999999;
}

.muted-btn:focus {
  outline: none;
}

/* Device */
/* .dropdown-toggle{
    width: 160px;

  } */



/* PoP Dashboard */
.main-box {
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 15px;
}

.main-box .heading {
  color: lightslategrey;
}

.temperature-exceed {
  text-align: center;
  background: red;
  border-radius: 5px;
  padding-top: 10px;
  color: white;
  overflow: hidden;
}
.standby-exceed {
  background: #fd7e14;
  color: white;
}
.temperature-box {
  text-align: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2);
  border-radius: 5px;
  padding-top: 10px;
  overflow: hidden;
}

.temperature-box p {
  margin-bottom: 10px;
}

.temperature-exceed p {
  margin-bottom: 10px;
}

.threshold {
  background: gray;
  color: white;
  font-size: 14px;
  padding: 2px 0;
}

.main-box .icon {
  font-size: 55px;
  color: rgba(0, 0, 0, .15);
  text-align: end;
  position: relative;
  top: -15px;
}

/* Ac Switch */
.ac-switch {
  position: relative;
  width: 70px;
  height: 30px;
  background: gray;
  border-radius: 30px;
  display: flex;
  margin: 0px auto;
  margin-bottom: 10px;

}

.switch-on {
  position: absolute;
  width: 50%;
  height: 100%;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  background: green;
  color: white;
  border-radius: 30px 0px 0px 30px;
}

.switch-on-button {
  position: absolute;
  width: 50%;
  right: 0;
  height: 100%;
  border: none;
  outline: none;
  background: #a9a9a9;
  color: white;
  border-radius: 0 30px 30px 0;
  -webkit-border-radius: 0 30px 30px 0;
  -moz-border-radius: 0 30px 30px 0;
  -ms-border-radius: 0 30px 30px 0;
  -o-border-radius: 0 30px 30px 0;
}

.form-switch {
  font-size: 22px;
}

.form-check-input:checked {
  background-color: green;
  border-color: green;
}

/* Loader Box */
.loader-box {
  position: relative;
  width: 100%;
  height: 97px;
  display: inline-block;
}