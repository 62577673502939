table td,
table th {
    text-align: center;
    padding: 0 10px;
    ;

}

.table-bordered {
    border: 0 solid #dee2e6 !important;
}

table th {
    color: white;
    background-color: #7b99c7;
}

.table-hover {
    background-color: ghostwhite !important;
}

tr:nth-child(even) {
    background: ghostwhite;
}

tr:nth-child(odd) {
    background: white;
}

.table-delete-btn {
    border: none;
    outline: none;
    color: red;
    background: transparent;
    margin-left: 15px;
}

.table-delete-btn:hover {
    color: #007afc;
}

.table-edit-btn {
    border: none;
    outline: none;
    color: gray;
    background: transparent;
}

.table-edit-btn:hover {
    color: #007afc;
}